<script>
// import NotificationsWidget from "@/views/widgets/notifications/index";
// import RemindersWidget from "@/views/widgets/reminders/index";
import { mapGetters, mapActions, mapMutations } from 'vuex';
//import Typeahead from '@/components/typeahead'
import http from '@/app-http'
import { debounce } from "lodash";
/**
 * Topbar component
 */
export default {

  data() {
    return {
      languages: [
      
      ],
      text: null,
      flag: null,
      value: null,
       contacts: [],
      contactsLoading: false,
    };
  },
  props: {

    type: {
      type: String,
      required: true,
    },
    layoutscroll: {
      type: Boolean,
      required: true,
    },
    pagetitle: {
      type: String,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
  },
  methods: {
   
    
     ...mapMutations('reminders', {
      editReminder: 'SET_EDITED_REMINDER',
    }),
    ...mapActions('auth', {
      logOut: 'logOut'
    }),
    
    tryLogOut(){
       this.logOut();
       this.$router.push( { name: 'login'} );
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    openContact(contact) {
      this.$router.push({ name: 'contact-details', params: { id: contact.id }});
    },
    displayContact(contact) {
      if(contact.phoneNumbers && contact.phoneNumbers.length > 0) {
        return contact.name + ' [ ' + contact.phoneNumbers.join() + ']';
      } else {
        return contact.name;
      }
    },
    async onContactSearch(e) {
       const req = {
          keyword: e.input,
          skip: 0,
          take: 50,
          orderBy: {
            field: 'Name',
            asc: true,
          }
        };
        this.contactsLoading = true;
        const response = await http.post(`contacts-search`, req);
        this.contactsLoading = false;
        this.contacts = response.data.items;
    },
    
    onDebContactSearch: debounce(function(e){
      this.onContactSearch(e);
    }, 600),
  },
  computed :{
    ...mapGetters('processTypes', {
      processTypes: 'processTypes'
    }),
    isChat() {
      return this.$route.name === 'chat';
    },
    isContacts() {
      return this.$route.name === 'contacts' || this.$route.name === 'contact-custom-fields';
    },
     isProcessList() {
      return this.$route.name === 'processes-list' ;
    },
    //  isProcessBoard() {
    //   return this.$route.name === 'processes-board';
    // },
    isProcesses() {
      return this.$route.name === 'process-list' 
      ||  this.$route.name === 'process-board'
      || this.$route.name === 'process-custom-fields' 
      || this.$route.name === 'process-types'
      || this.$route.name === 'process-type-edit';
    },
    ...mapGetters('auth', {
        currentUser: 'currentUser'
    }),
  },
  components: {
    // NotificationsWidget,
    // RemindersWidget,
    // Typeahead
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
             
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutscroll: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case false:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
            case true:
              document.body.setAttribute("data-layout-scrollable", "true");
              break;
            default:
              document.body.setAttribute("data-layout-scrollable", "false");
              break;
          }
        }
      },
    },
  }
};
</script>

<template>
  <header id="page-topbar" class="isvertical-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-dark-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark-sm.png" alt="" height="22" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="22" />
            </span>
            <span class="logo-sm">
              <img src="@/assets/images/logo-light-sm.png" alt="" height="22" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="
            btn btn-sm
            px-3
            font-size-16
            header-item
            vertical-menu-btn
            topnav-hamburger
          "
          @click="toggleMenu"
        >
          <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div class="d-none d-sm-block ms-3 align-self-center">
          <h4 class="page-title">
            <span class="me-2">{{ pagetitle }}</span>
            <i class="fa fa-spin fa-spinner" v-if="isBusy"></i>
          </h4>
        </div>
      </div>

      <div class="d-flex top-toolbar table-responsive">

        <!-- <div class="dropdown">
          <button
            type="button"
            class="btn header-item"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <eva-icon class="icon-sm" name="search-outline"></eva-icon>
          </button>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg p-0">
            <form class="p-2">
              <typeahead
                placeholder="Search contact..."
                :items="contacts"
                :busy="contactsLoading"
                :minInputLength="2"
                :itemProjection="displayContact"
                @selectItem="openContact"
                @onInput="onDebContactSearch"
                @onFocus="contacts=[]"
              >
              </typeahead>
            </form>
          </div>
        </div> -->

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown-v"
            data-bs-toggle="dropdown"
            data-bs-reference="parent"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi-plus-circle-multiple-outline mdi font-size-24"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end p-2">
            <a 
              href="javascript:void(0)" 
              class="dropdown-item" 
              @click="editProcess({ process: { processType: pt}})" 
              v-for="pt in processTypes" :key="pt.id"
            >
              {{pt.name}}
            </a>
            <hr/>
            <a href="javascript:void(0)" class="dropdown-item" @click="editContact('new')">
              Contact
            </a>
            <a href="javascript:void(0)" class="dropdown-item" @click="editReminder({ reminder: {}})">
              Reminder
            </a>
          </div>
        </div> -->
        
        <!-- <reminders-widget></reminders-widget> -->
        <!-- <notifications-widget></notifications-widget> -->
        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            id="page-header-notifications-dropdown-v"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <eva-icon class="icon-sm" name="bell-outline"></eva-icon>
            <span class="noti-dot bg-danger rounded-pill">4</span>
          </button>

          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown-v"
          >
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 font-size-15">Notifications</h5>
                </div>
                <div class="col-auto">
                  <a
                    href="javascript:void(0)"
                    class="small fw-semibold text-decoration-underline"
                  >
                    Mark all as read</a
                  >
                </div>
              </div>
            </div>
            <SimpleBar data-simplebar style="max-height: 250px">
              <a href="javascript:void(0)" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      class="rounded-circle avatar-sm"
                      alt="user-pic"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h6 class="mb-1">James Lemire</h6>
                    <div class="font-size-13 text-muted">
                      <p class="mb-1">It will seem like simplified English.</p>
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        <span>1 hour ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0)" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="flex-shrink-0 avatar-sm me-3">
                    <span
                      class="
                        avatar-title
                        bg-primary
                        rounded-circle
                        font-size-16
                      "
                    >
                      <i class="bx bx-cart"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <h6 class="mb-1">Your order is placed</h6>
                    <div class="font-size-13 text-muted">
                      <p class="mb-1">
                        If several languages coalesce the grammar
                      </p>
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        <span>3 min ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <a href="javascript:void(0)" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="flex-shrink-0 avatar-sm me-3">
                    <span
                      class="
                        avatar-title
                        bg-success
                        rounded-circle
                        font-size-16
                      "
                    >
                      <i class="bx bx-badge-check"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <h6 class="mb-1">Your item is shipped</h6>
                    <div class="font-size-13 text-muted">
                      <p class="mb-1">
                        If several languages coalesce the grammar
                      </p>
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        <span>3 min ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>

              <a href="javascript:void(0)" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-3">
                    <img
                      src="@/assets/images/users/avatar-6.jpg"
                      class="rounded-circle avatar-sm"
                      alt="user-pic"
                    />
                  </div>
                  <div class="flex-grow-1">
                    <h6 class="mb-1">Salena Layfield</h6>
                    <div class="font-size-13 text-muted">
                      <p class="mb-1">
                        As a skeptical Cambridge friend of mine occidental.
                      </p>
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        <span>1 hour ago</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </SimpleBar>
            <div class="p-2 border-top d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 btn-block text-center"
                href="javascript:void(0)"
              >
                <i class="uil-arrow-circle-right me-1"></i>
                <span>View More..</span>
              </a>
            </div>
          </div>
        </div> -->

        <!-- <div class="dropdown d-inline-block" v-if="isProcessList || isProcessBoard">
          <router-link
            v-if="isProcessList"
            to="/processes-board"
           
            class="btn header-item user text-start d-flex align-items-center"
          >
            <i class=" mdi mdi-bulletin-board font-size-24"></i>
          </router-link>
          <router-link
            v-if="isProcessBoard"
            to="/processes-list"
            
            class="btn header-item user text-start d-flex align-items-center"
          >
            <i class=" mdi mdi-format-list-bulleted font-size-24"></i>
          </router-link>
        </div> -->
        
        

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown-v"
            data-bs-toggle="dropdown"
            data-bs-reference="parent"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi-face mdi font-size-24"/>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0">
            <div class="p-3 border-bottom">
              <h6 class="mb-0">{{(currentUser || {}).name}}</h6>
              <p class="mb-0 font-size-11 text-muted">
                {{((currentUser || {}).account || {}).name}}
              </p>
            </div>
            <router-link class="dropdown-item" to="/profile"
              ><i
                class="
                  mdi mdi-account-circle
                  text-muted
                  font-size-16
                  align-middle
                  me-1
                "
              ></i>
              <span class="align-middle">Profile</span></router-link
            >
            <!-- <a class="dropdown-item d-flex align-items-center" href="#"
              ><i
                class="
                  mdi mdi-cog-outline
                  text-muted
                  font-size-16
                  align-middle
                  me-1
                "
              ></i>
              <span class="align-middle">Settings</span
              >
            </a> -->
            <a class="dropdown-item" href="javascript:void(0)" @click="tryLogOut"
              ><i
                class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
              ></i>
              <span class="align-middle">Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
