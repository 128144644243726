<script>

import { mapGetters, mapMutations } from 'vuex';
import StatusColor from '../directories/status-color.vue';
import Pagination from '@/components/pagination';
import http from '@/app-http'

export default {
  components: {
    StatusColor,
    Pagination
  },
  created() {
   
  },  
  props: {
    item: {
      type: Object,
      required: true
    },
    relField: {
      type: Object,
      required: true
    },
    relDirectory: {
      type: Object,
      required: true
    },
  },
   data() {
    return {
      items: [],
      loading: false,
      currentPage: 1,
      perPage: 50,
      total: 0
    };
  },
  async mounted(){
    this.loading = true;
    await this.load();
    this.loading = false;
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs'
    }),
    itemId() {
      return this.item?.id;
    },
    newItemVisible() {
      return this.item?.directoryId === "79663b0a719a4dd893c50c78110b7ef0" && this.relDirectory.id === '1ee317c47fb34cb3888f0788ccf19d0b' ? false : true;
    }
  },
  watch: {
   currentPage(newValue, oldValue) {
    if(newValue != oldValue) {
      this.load();
    }
   }
  },
  methods: {
    ...mapMutations('directoryItems', {
       openItem: 'OPEN_ITEM'
    }),
    onNew(){
      const template = {
        customFields:{}
      };
      
      if(this.item.directoryId === "79663b0a719a4dd893c50c78110b7ef0") {
        if(this.relDirectory.id === '04e0a88c3ea5467bacf845fd1fbdc9a2') { // request
          template.customFields['1eddb3b0-578b-11ed-bcf6-39325bf8a98e'] = this.item;
        }
      } else if(this.item.directoryId === "04e0a88c3ea5467bacf845fd1fbdc9a2") {
        if(this.relDirectory.id === '1ee317c47fb34cb3888f0788ccf19d0b') { // request
          template.customFields['578fad40-5799-11ed-b16f-6fb1bf8d0249'] = this.item;
        }
      }
    
      const o = { 
          item: { directoryId: this.relDirectory.id }, 
          mode: 'edit', 
          onSave: this.load, 
          template,
          closeOnSave: true,
          onClose: () => {
           
          }
        };
        console.log('apply', o)
        this.openItem(o);
    },
    async load() {
      this.loading = true;
      const req = {
        conditions:[
          { 
            field: this.relField.id , 
            value: {
              stringValue: this.itemId
            },
            lgOp: 'And',
            op:'Equal'
          }
        ],
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
        orderBy:{
          field: 'Number',
          asc: false
        }
      };
      
      const resp = await http.post(`directories/${this.relDirectory.id}/items/search`, req);
      this.total = resp.data.total;
      this.items = resp.data.items;
      this.loading = false;
    }
  }
};
</script>

<template>
  <div>
    <div class="d-flex justify-content-end" v-if="newItemVisible">
      <button class="btn btn-light btn-sm mt-2 mb-2 " @click="onNew">
        <i  class="fa fa-plus me-1"></i>
        Новий запис
      </button>
    </div>
   
    <div class="table-responsive">
      <table class="table table-sm table-stripped text-nowrap">
        <thead class="table-light">
          <tr>
            <th style="width:40px; max-width:40px;">

            </th>
            <th style="width:40px; ">
              #
            </th>
            <th v-if="relDirectory.type === 'Process'">{{$t('directory.fields.status')}}</th>
            <template v-for=" field in relDirectory.fields.filter(x => x.visibleOnGrid)" :key="field.id">
              <th v-if="field.id !== relField.id">
                {{field.name}}
              </th>
            </template>
            
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="100">
              <div class="text-muted ">
                <i class="fa fa-spin fa-spinner me-2"></i> {{$t('common.loading')}}
              </div> 
            </td>
          </tr>
          <tr  v-if="!loading && items.length === 0">
            <td colspan="100">
              <div class="text-muted ">
                {{$t('common.noRecordsFound')}}
              </div> 
            </td>
          </tr>
          <tr v-for="item in items" :key="item.id"> 
            <td style="width:40px; max-width:40px;">
              <a href="javascript:void(0)" class="text-primary" @click="openItem({item, mode: 'view', onClose: load})"><i class="bx bx-detail font-size-16"></i></a>
            </td>
            <td style="width:40px; ">
              <b>{{item.number}}</b>
            </td>
            <td v-if="relDirectory.type === 'Process'">
              <status-color :color="(relDirectory.statusList.find(x => x.id === item.status)|| {}).color"></status-color>
              {{(relDirectory.statusList.find(x => x.id === item.status)|| {}).name}}
            </td>
            <template v-for=" field in relDirectory.fields.filter(x => x.visibleOnGrid)" :key="field.id">
               <td v-if="field.id !== relField.id">
                  <custom-field 
                    :def="field" 
                    :value="item.customFields[field.id]"
                    :fields="relDirectory.fields"
                    :item="item"
                  >
                  </custom-field>
              </td>
            </template>
           </tr> 
        </tbody>
      </table>
    </div>
    
    <div>
      <pagination :page="currentPage" :total="total" :per-page="perPage" @change="(v) => currentPage = v" small/>
    </div>
  </div>
</template>
