<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import ReminderEditForm from "@/views/pages/reminders/edit-form"
import DirectoryItemForm from "@/views/pages/directory-items/form"
import DataTransformationForm from "@/views/pages/directory-items/data-transformation-form"
import FilePreviewForm from "@/views/pages/directory-items/file-preview-form"
import CustomFieldTableEdit from '@/components/custom-fields/custom-field-table-edit';
import AddressForm from '@/components/custom-fields/address-form';
import Boot from "@/views/boot"
import { mapGetters, mapMutations } from 'vuex';
import ViewForm from '@/views/pages/reminders/view-form'


export default {
  props: {
    pagetitle: {
      type: String,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Vertical,
    Horizontal,
    ReminderEditForm,
    DirectoryItemForm,
    CustomFieldTableEdit,
    FilePreviewForm,
    Boot,
    ViewForm,
    AddressForm,
    DataTransformationForm
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('directoryItems', {
      directorySelectedItems: 'selectedItems',
      filePreviews:'filePreviews',
      dataTranformation: 'dataTranformation'
    }),
     ...mapGetters('customFields', {
      tableForms: 'tableForms',
      addressForms: 'addressForms',
    }),
    ...mapGetters('reminders', {
      activeReminders: 'activeReminders'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...layoutComputed,
    
    ...mapGetters('processes', {
      editedProcess: 'editedProcess'
    }),
    ...mapGetters('reminders', {
      selectedReminder: 'selectedReminder'
    }),
    ...mapGetters('reminders', {
      editedReminder: 'editedReminder'
    }),
  },
  methods: {
  
     ...mapMutations('reminders', {
      cancelReminderView: 'UNSELECT_REMINDER',
      cancelReminderEdit: 'RESET_EDITED_REMINDER'
    }),
    ...mapMutations('processes', {
      cancelProcessEdit: 'RESET_EDITED_PROCESS'
    }),
    ...mapMutations('directoryItems', {
      hideDirectoryItem: 'HIDE_ITEM',
      hideFilePreviewForm: 'HIDE_FILE_PREVIEW_FORM',
      hideDataTransformationForm: 'HIDE_DATA_TRANSFORMATION_FORM'
    }),
    ...mapMutations('customFields', {
      hideTableValueForm: 'HIDE_TABLE_VALUE_FORM',
      hideAddressForm: 'HIDE_ADDRESS_FORM'
    }),
     onContactFormClose() {
      this.cancelContactEdit();
    },
    closeDirectoryItemForm(elem) {
      this.hideDirectoryItem();
      if(elem.onClose) {
        elem.onClose();
      }
    },
    onHideDataTransformationForm(e) {
      this.hideDataTransformationForm();
      if(e.onClose) {
        e.onClose();
      }
    }
  },
  mounted() {
  },
};
</script>

<template>
  <div>
    <data-transformation-form v-for="(elem, index) in dataTranformation" 
      :key="index" 
      :rule-id="elem.ruleId" 
      :source-id="elem.sourceId" 
      @close="onHideDataTransformationForm(elem)"
      > 

    </data-transformation-form>

    <file-preview-form v-for="(elem, index) in filePreviews"  :key="index" :url="elem.url" :file-name="elem.fileName"  @close="hideFilePreviewForm">

    </file-preview-form>

    <address-form  v-for="(elem, index) in addressForms" :key="index" :address="elem.address" :editable="elem.editable || false" @close="hideAddressForm" @save="(o) => elem.onSave(o, elem.arg)">

    </address-form>

    <custom-field-table-edit  v-for="(elem, index) in tableForms" :key="index" :def="elem.def" :value="elem.value" @close="hideTableValueForm" @save="(o) => elem.onSave(o, elem.arg)">

    </custom-field-table-edit>

    <directory-item-form v-for="(elem, index) in directorySelectedItems" 
      :key="index" 
      :input="elem.item" 
      :mode="elem.mode" 
      :template="elem.template"
      :closeOnSave="elem.closeOnSave"
      :transformation="elem.dataTransformation"
      @save="elem.onSave"
      @close="() => closeDirectoryItemForm(elem)">
    </directory-item-form>

    <view-form v-for="(reminder, index) in selectedReminder" :key="index" :reminder="reminder" @close="cancelReminderView">
    </view-form>


    <reminder-edit-form v-if="editedReminder" :reminder="editedReminder" @close="cancelReminderEdit" >
    </reminder-edit-form>

    <Vertical :pagetitle="pagetitle" v-if="layoutType === 'vertical'" :layout="layoutType" :is-busy="isBusy">
      <slot ></slot>
    </Vertical>

    <Horizontal :pagetitle="pagetitle" v-if="layoutType === 'horizontal'" :layout="layoutType" :is-busy="isBusy">
      <slot ></slot>
    </Horizontal>

    <boot>

    </boot>

    <!-- <div v-if="!isAccountReady"> 
      <slot />
    </div> -->
  </div>
</template>
