<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import Swal from 'sweetalert2'

export default {
  components: {
   
  },
  props: {
    itemId: {
      type: String,
      required: true
    },
    directoryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      text: null,
      editingNote: null,
      processing: false,
      loading: false,
      deleting: false
    };
  },
  async mounted(){
    const { directoryId, itemId } = this;
    this.loading = true;
    await this.loadFiles({directoryId, itemId});
    this.loading = false;
  },
  computed :{
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
       token: 'token'
    }),
    ...mapGetters('directoryItems', {
      filesItems: 'files'
    }),
    directory () {
      return this.directories.find(x => x.id === this.directoryId);
    }, 
    files() {
      return this.filesItems[this.itemId] || [];
    },
    allowDelete() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowDeleteFile;
    },
  },
 
  methods: {
    ...mapMutations('directoryItems', {
      showFilePreview: 'SHOW_FILE_PREVIEW'
    }),
    ...mapActions('directoryItems', {
      loadFiles: 'loadFiles',
      deleteFile: 'deleteFile'
    }),
    fileUrl(file) {
       const { directoryId, itemId } = this;
      return `${process.env.VUE_APP_URL}directories/${directoryId}/items/${itemId}/files/${file.id}?token=${this.token}`;
    },
    tryToDelete(id) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          const { directoryId, itemId } = this;
          this.deleting  = id;
          this.deleteFile({id: id, directoryId, itemId}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
             this.deleting  = false;
          });
        }
      });
    },
    showPreview(url, fileName) {
      console.log('showPreview');
      this.showFilePreview({url, fileName});
    }
  }
};
</script>

<template>
  <div>
    <div class="text-muted px-2 mb-4" v-if="files.length === 0">
      {{$t('common.noRecordsFound')}}
    </div> 
    <div class="row">
      <div 
        v-for="(file) in files"
        :key="file.id"
        class="col-12 mb-4 p-2"
        style="position: relative;  "
      >
        <div class="d-flex flex-column flex-grow h-100 align-content-center ps-2 pt-2 pe-2 border" style="position: relative; padding-bottom: 40px; !important">
          <a href="javascript:void(0)" @click="showPreview(fileUrl(file), file.fileName)" class="d-block" v-if="file.isImage || file.isPdf">

            <img :src="fileUrl(file)"  class="img-thumbnail bg-white border-none" style="border: none !important;" v-if="file.isImage" />
            <img src="/imgs/file-pdf.png" class="img-thumbnail bg-white border-none" style="border: none !important;"  v-if="file.isPdf"/>
            <br/>
            <div class="text-nowrap text-truncate">
              <small class="">{{file.fileName}}</small>
            </div>
          </a>

          <a :href="fileUrl(file)" target="_blank" class="d-block" v-if="!file.isImage && !file.isPdf">
            <img src="/imgs/file-word.png" class="img-thumbnail bg-white border-none"  style="border: none !important;" v-if="file.isWord "/>
            <img src="/imgs/file-excel.png" class="img-thumbnail bg-white border-none"  style="border: none !important;" v-if="file.isExcel"/>
            <img src="/imgs/file.png" class="img-thumbnail bg-white border-none" style="border: none !important;"   v-if="!file.isWord && !file.isExcel && !file.isPdf && !file.isImage "/>
            <br/>
            <div class="text-nowrap text-truncate">
              <small class="">{{file.fileName}}</small>
            </div>
          </a>
          <div class=""></div>
          <button
              v-if="allowDelete && !file.external"
              @click="tryToDelete(file.id)"
              href="javascript:void(0);"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete"
              class=" btn btn-sm btn-danger text-nowrap text-truncate "
              style="bottom:6px; position: absolute; left: 6px; right: 6px"
          
            >
            <font-awesome-icon icon="fa-solid fa-trash-can" v-if="deleting !== file.id"/>
            <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="deleting === file.id"/>
            {{$t('common.deleteButtonTitle')}}
          </button>
        </div>
        
      </div>
    </div>
  </div>
</template>
