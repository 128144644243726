<script>
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { uuid } from 'vue-uuid';


export default {
  components: {
   
  },
  props: {
    ruleId:{
       type: String,
       required: true
    },
    sourceId:{
       type: String,
       required: true
    },
    targetId:{
       type: String,
       required: false
    }
  },
  data() {
    return {
      source: null,
      target: null,
      targetNumber: '',
      targetLoading: false,
      loading: false,
      title: '',
      errors: [],
      mapping: []
    };
  },
  created() {
    this.init();
    this.title = this.rule.name;
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    ...mapGetters('rules', {
      allDataTransformation: 'dataTransformation'
    }),
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs'
    }),
    ...mapGetters('directoryItems', {
      processing: 'processing',
      deleting: 'deleting',
      allTags: 'tags'
    }),
    rule () {
       var res = this.allDataTransformation.find(x => x.id === this.ruleId);
       return res;
    }, 
    sourceDirectory() {
      if(this.rule) {
        return this.directories.find(x => x.id === this.rule.sourceDirectoryId);
      }
      return null;
    },
    targetDirectory() {
      if(this.rule) {
        return this.directories.find(x => x.id === this.rule.targetDirectoryId);
      }
      return null;
    }
  }, 
  methods: {
    ...mapMutations('directoryItems', {
      openItem: 'OPEN_ITEM'
    }),
    ...mapActions('directoryItems', {
      create: 'create',
      update: 'update',
      loadById: 'loadById',
      loadByNumber: 'loadByNumber'
    }),
    
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    
    async init() {
      await this.reload();
    },
    async loadTarget() {
      this.targetLoading = true;
      this.target = await this.loadByNumber({ directoryId: this.rule.targetDirectoryId, number: this.targetNumber });
      this.targetLoading = false;
    },
    async reload() {
      this.loading = true;

      this.source = await this.loadById({ directoryId: this.rule.sourceDirectoryId, id: this.sourceId });
      if(this.targetId) {
        this.target = await this.loadById({ directoryId: this.rule.targetDirectoryId, id: this.targetId });
      }
      
      let m = [];
      if(this.sourceDirectory && this.targetDirectory) {
        this.rule.mapping.forEach(mapping => {
          const o = {
            ...mapping,
            sourceField: mapping.sourceFieldId === '$source' 
              ? null : this.sourceDirectory.fields.find(x => x.id === mapping.sourceFieldId),
            targetField: this.targetDirectory.fields.find(x => x.id === mapping.targetFieldId),
            selected:  mapping.sourceFieldId === '$source' ? this.source : (this.source.customFields[mapping.sourceFieldId] || []).filter(x => !x['$_META']),
            target: null
          };
          m.push(o);
          this.calculateTarget(o);
        });
        // this.sourceDirectory.fields.forEach(field => {
        //   const mapping = this.rule.mapping.find(x => x.sourceFieldId === field.id);
        //   if(mapping) {
        //     const targetField = this.targetDirectory.fields.find(x => x.id === mapping.targetFieldId);
        //     if(targetField) {
        //       const o = {
        //         ...mapping, 
        //         sourceField: field, 
        //         targetField: targetField,
        //         selected: this.source.customFields[field.id],
        //         target: null
        //       };
              
        //     }
        //   }
        //});
      }
      this.mapping = m;

      this.loading = false;
    },
    selectedChanged(a, m) {
     
      m.selected = a;
      this.calculateTarget(m);
    },
    calculateTarget(m) {
      if(m.sourceField.type !== 'Table') {
        m.target = m.selected;
      } else {
        let table = m.selected.map(sourceRow => {
          const targetRow = {};
          m.mapping.forEach(tm => {
            const tableSourceField = m.sourceField.tableFields.find(x => x.id === tm.sourceFieldId);
            const tableTargetField = m.targetField.tableFields.find(x => x.id === tm.targetFieldId);
            if((tm.sourceFieldId === '$source' || tableSourceField) && tableTargetField) {
              if(tm.sourceFieldId === '$source') {
                targetRow[tableTargetField.id] = this.source;
              } else {
                targetRow[tableTargetField.id] = sourceRow[tableSourceField.id];
              }
            }
          });
         
          targetRow['$_ID'] = uuid.v1();
          sourceRow['$TARGET_ID'] =  targetRow['$_ID'];
          targetRow['$_META'] = {
            linkedTo: this.source,
            source: false,
            target: true,
            allowEdit: false,
            allowDelete: false
          };
          return targetRow;
        });
        m.target = table;
      }
    },
    apply() {
      const template = {
        customFields:{}
      };
      this.mapping.forEach(mapping => {
        template.customFields[mapping.targetField.id] = mapping.target;
      });

      if(this.ruleId == 'AA4FDA3A-41AB-4E5D-8522-E3238EF00DDC') {
        template.customFields['f53eb010-b2be-11ed-b651-0b4fba2e24b7'] = [ { 'a6181580-b2be-11ed-b651-0b4fba2e24b7': this.source } ]
      }
      
      if(this.ruleId == '1AA4FDA3A-41AB-4E5D-JJ522-E3238EF00DDC') {
        console.log('this.target.customFields', this.target.customFields);
        if(!((this.target.customFields['f53eb010-b2be-11ed-b651-0b4fba2e24b7'] || []).find(x => x['a6181580-b2be-11ed-b651-0b4fba2e24b7']?.id === this.source.id))) {
          template.customFields['f53eb010-b2be-11ed-b651-0b4fba2e24b7'] = [...this.target.customFields['f53eb010-b2be-11ed-b651-0b4fba2e24b7'] || [], { 'a6181580-b2be-11ed-b651-0b4fba2e24b7': this.source }];
        }
        
        //template.customFields['f53eb010-b2be-11ed-b651-0b4fba2e24b7'] = [ { 'a6181580-b2be-11ed-b651-0b4fba2e24b7': this.source } ]
      }

      const onSave = (res) => {
        this.done = true;
        this.res = res;
      }
      
      const dataTransformation = {
        ruleId: this.ruleId,
        sourceId: this.sourceId,
        items: this.mapping.map(mapping => {
          return {
            sourceFieldId: mapping.sourceField.id,
            targetFieldId: mapping.targetField.id,
            tableRows: mapping.sourceField.type === 'Table' ?  
              mapping.selected.map(row => ({
                sourceRowId: row['$_ID'],
                targetRowId: row['$TARGET_ID']
              })) : []
          };
        })
      };

      if(this.rule.type === 'Create') {
        const o = { 
          item: { directoryId: this.rule.targetDirectoryId }, 
          mode: 'edit', 
          onSave: onSave, 
          template,
          dataTransformation,
          closeOnSave: true,
          onClose: () => {
            if(this.done) {
              this.$emit('executed')
              this.close();
            }
          }
        };
        console.log('apply', o)
        this.openItem(o);
      } else {
        const o = { 
          item: this.target, 
          mode: 'edit', 
          onSave: onSave, 
          template,
          dataTransformation,
          closeOnSave: true,
          onClose: () => {
            if(this.done) {
              this.$emit('executed')
              this.close();
            }
          }
        };
        console.log('apply', o)
        this.openItem(o);
      }
    }
  }
}
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-lg" 
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col">
            <h5 class="modal-title me-4">
              
              {{title}}
              <i class="fa fa-spin fa-spinner ms-2" v-if="loading"></i>
            </h5>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="source">
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>
            <template v-for="(m,mi) in mapping" :key="mi">
              <template v-if="m.sourceField.type !== 'DirectoryRelatedField'">
                <div class="mb-3" :title="m.sourceField.name"  :class="{'row': sourceDirectory.inlineLabel}">
                  <label class="mb-0 dir-item-field-label" :class="{'col-lg-3': sourceDirectory.inlineLabel}">{{m.sourceField.name}}</label>
                  <div :class="{'col-lg-9': sourceDirectory.inlineLabel}">
                    <custom-field 
                      selectable
                      :selected="m.selected"
                      :def="m.sourceField" 
                      :value="source.customFields[m.sourceField.id]" 
                      @changed="reload"
                      @selected-changed="a => selectedChanged(a, m)"
                    >
                    </custom-field>
                    <div v-if="!source.customFields[m.sourceField.id]" class="text-muted">{{$t('common.noValue')}}</div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div v-if="rule.type === 'Append' && !loading">
            <hr/>
          
            <div v-if="!target">
              <label class="mb-0 dir-item-field-label">{{targetDirectory.name}}</label>
              <div class="input-group">
               
                <input type="text" class="form-control" placeholder="#" v-model='targetNumber'/>
                <button class="btn btn-secondary" type="button" @click="loadTarget" :disabled="!targetNumber">
                  <font-awesome-icon icon="fa-solid fa-search" v-if='!targetLoading'/>
                  <font-awesome-icon icon="fa-solid fa-spinner" v-if='targetLoading' class="fa-svg-spin" />
                </button>
              </div>
              
            </div>
            <div  v-if="target">
              <a class="mb-3" href="javascript:void(0)" @click="openItem({ item: target, mode: 'view'});">{{targetDirectory.name}} #{{target.number}}</a>
              <template v-for="(m,mi) in mapping" :key="mi">
                <template v-if="m.targetField.type !== 'DirectoryRelatedField'">
                  <div class="mb-3" :title="m.targetField.name"  :class="{'row': targetDirectory.inlineLabel}">
                    <label class="mb-0 dir-item-field-label" :class="{'col-lg-3': targetDirectory.inlineLabel}">{{m.targetField.name}}</label>
                    <div :class="{'col-lg-9': targetDirectory.inlineLabel}">
                      <custom-field 
                        :def="m.targetField" 
                        :value="target.customFields[m.targetField.id]" 
                      >
                      </custom-field>
                      <div v-if="!target.customFields[m.targetField.id]" class="text-muted">{{$t('common.noValue')}}</div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-light me-2"
              @click="close"
            >
              {{$t('common.closeButtonTitle')}}
            </button>
          </div>
          <div class="col text-end">
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="apply"
              :disabled="rule.type !== 'Create' && !target"
            >
               {{$t('common.applyBtnTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
