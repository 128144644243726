<script>
import { mapGetters, mapActions } from 'vuex';

import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { parseErrors} from "@/helpers"
import UserSelection from '@/components/user-selection';
import { popModal, pushModal } from "@/modals";
import Typeahead from '@/components/typeahead'
import http from '@/app-http'
import { debounce } from "lodash";
import Swal from 'sweetalert2'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    UserSelection,
    Typeahead
  },
  props: {
    reminder:{
       type: Object,
       required: true
    }
  },
  data() {
    return {
      contacts: [],
      contactsLoading: false,
      allowEdit: false,
      allowCancel: false,
      allowDelete: false,
      errors: [],
      item: {
       
      }
    };
  },
  validations() {
    const rules = {
      item: {
        subject: { required },
        date: { required }
      }
    };

    return rules;
  },
  created(){
    this.title = this.isNew ? "New Reminder" : "Edit Reminder";
    this.populate(this.reminder);
    if(this.isNew) {
      this.onContactSearch({input: ''});
      if(this.reminder.contact && this.reminder.contact.manager) {
        this.item.users = [this.reminder.contact.manager];
      } else if(this.reminder.process) {
        if( this.reminder.process.manager) {
          this.item.users = [this.reminder.process.manager];
        }else if(this.reminder.process.contact && this.reminder.process.contact.manager){
           this.item.users = [this.reminder.process.contact.manager];
        }
      }

      if(this.item.users.length === 0) {
        this.item.users = [this.currentUser];
      } 
    }
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    isNew () {
      return !this.item.id ? true : false;
    },
    ...mapGetters('reminders', {
      processing: 'processing',
      deleting: 'deleting',
    }),
    ...mapGetters('users', {
      users: 'itemsOnlyAlive'
    })
  },
  methods: {
    ...mapActions('reminders', {
      createReminder: 'createReminder',
      updateReminder: 'updateReminder',
      deleteReminder: 'deleteReminder',
      cancelReminder : 'cancelReminder'
    }),
    async close(res) {
       var modal = Modal.getInstance(this.$refs.modal);
       modal.hide();
        popModal();
       this.$emit("close", res);
    },
    displayContact(contact) {
      if(contact.phoneNumbers && contact.phoneNumbers.length > 0) {
        return contact.name + ' [ ' + contact.phoneNumbers.join() + ']';
      } else {
        return contact.name;
      }
    },
    async onContactSearch(e) {
       const req = {
          keyword: e.input,
          skip: 0,
          take: 50,
          orderBy: {
            field: 'Name',
            asc: true,
          }
        };
        this.contactsLoading = true;
        const response = await http.post(`contacts-search`, req);
        this.contactsLoading = false;
        this.contacts = response.data.items;
    },
    
    onDebContactSearch: debounce(function(e){
      this.onContactSearch(e);
    }, 600),
    async populate(source) {
     
      this.allowEdit = !this.isNew ? true : source.allowEdit;
      this.allowCancel = !this.isNew ? true : source.allowCancel;
      this.allowDelete = !this.isNew ? true : source.allowDelete;

      const {
        id,
        number,
        subject,
        date,
        time,
        text,
        users,
        contact,
        process
      } = source;

      this.item = {
        id,
        number,
        subject,
        date,
        time,
        text,
        contact,
        process,
        users: (users || []).map(x => x.user)
      };
    },
    async submit (next) {
      this.errors = [];
      let isValid = await this.v$.$validate();
      if(this.item.users.length === 0) {
        this.errors = ['Please select at least one user for whom the reminder is.'];
        isValid = false;
      }
      if(isValid) {
        this.errors = [];
        const {
          id,
          subject,
          date,
          time,
          text,
          users,
          contact,
          process
        } = this.item;
        const dto = {
          subject,
          date,
          time,
          text,
          contactId: (contact || {}).id || null,
          processId: (process || {}).id || null,
          users: users.map(x => x.id)
        };
        let req = this.isNew ? this.createReminder({ dto }) : this.updateReminder({ id , dto});
        req.then((reminder) => {
          this.$toast.open({message: 'Data was saved successfully.'});
          this.close(reminder);
          if(next === 'details') {
            window.setTimeout(() =>{
              this.$router.push({name: 'reminder-details', params: { id: reminder.id }});
            }, 500);
          }
        })
        .catch(error => {
          console.log('error', error);
          this.errors = parseErrors(error);
        });
      }
    },
    onDelete( ) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
         
          this.deleteReminder({id: this.item.id }).then(()=>{
            Swal.fire("Deleted!", "Reminder has been deleted.", "success");
            this.close();
          });
        }
      });
    },
     onCancel( ) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.value) {
          this.cancelReminder({id: this.item.id }).then(()=>{
            Swal.fire("Cancelled!", "Reminder has been cancelled.", "success");
            this.close();
          });
        }
      });
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
             <span v-if="item.number" class="me-2">
              # {{item.number}}
            </span>
            {{title}}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

            <div class="row" v-if="!item.process" >
              <div class="col-md-12">
                <div class=" mb-2 row">
                  <label for="name-input" class="col-form-label col-lg-3">Contact</label>
                  <div class="col-lg-9"  v-if="item.contact">
                    <div class="input-group">
                      <div class="form-control">
                        <router-link :to="{name:'contact-details', params: { id: item.contact.id }}">
                          {{item.contact.name}}
                        </router-link>
                      </div>
                        <button class="btn btn-outline-secondary" type="button" @click="item.contact = null" v-if="isNew">
                          Change
                        </button>
                    </div>
                  </div>
                  <div class="col-lg-9" v-if="isNew && !item.contact">
                    <typeahead
                      placeholder="Search contact..."
                      :items="contacts"
                      :busy="contactsLoading"
                      :minInputLength="2"
                      :itemProjection="displayContact"
                      @selectItem="(contact) => item.contact = contact"
                      @onInput="onDebContactSearch"
                    >
                    </typeahead>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="item.process">
              <div class="col-md-12">
                <div class="mb-2 row">
                  <label for="Subject-input" class="col-form-label col-lg-3"></label>
                  <div class="col-lg-9 pt-2">
                    <b >#{{item.process.number}}</b> <span>{{item.process.processType.name}}</span>  [<state-name :state="item.process.state"></state-name>]
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12">
                <div class="mb-2 row">
                  <label for="Subject-input" class="col-form-label col-lg-3">Subject</label>
                  <div class="col-lg-9">
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="Enter Subject" 
                      id="Subject-input" 
                      v-model="item.subject"
                      :class="{ 'is-invalid': v$.item.subject.$error }"
                    />
                    <div class="invalid-feedback">
                      Please Enter Subject
                    </div> 
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="mb-2 row">
                  <label for="Date-input" class="col-form-label col-lg-3">Date & Time</label>
                  <div class="col-lg-5">
                    <input 
                      type="date" 
                      class="form-control mb-1" 
                      placeholder="Enter Date" 
                      id="Date-input" 
                      v-model="item.date"
                      :class="{ 'is-invalid': v$.item.date.$error }"
                    />
                    <div class="invalid-feedback">
                      Please Enter Date
                    </div> 
                  </div>
                  <div class="col-lg-4">
                    <input 
                      type="time" 
                      class="form-control mb-1" 
                      placeholder="Enter Time" 
                      id="Time-input" 
                      v-model="item.time"
                     
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="Manager-input" class="col-form-label  col-lg-3">Who is the reminder for</label>
              <div class="col-lg-9">
                <div>
                  <user-selection 
                    :value="item.users" 
                    @update="v => item.users = v"
                  >
                  </user-selection>
                </div>
              </div>
            </div>
           
           
            <div class=" mb-2 row">
              <label for="description-input" class="col-form-label  col-lg-3">Description</label>
              <div class="col-lg-9">
                <textarea 
                  rows="5"
                  class="form-control" 
                  placeholder="Enter Description" 
                  id="description-input" 
                  v-model="item.text"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-auto">
            <button
              v-if="allowCancel"
              type="button"
              class="btn btn-warning me-2"
              @click="onCancel"
            >
              Cancel
            </button>

             <button
              v-if="allowDelete"
              type="button"
              class="btn btn-danger"
              @click="onDelete"
            >
              Delete
            </button>

          </div>
          <div class="col text-end">
            <button
              type="button"
              class="btn btn-light me-2"
              @click="close"
            >
              Close
            </button>
          
            <button type="button" class="btn btn-primary" 
              :disabled="processing || deleting" 
              @click="submit"
            >
              <i class="fa fa-spin fa-spinner me-2" v-if="processing"></i>
              <i class="fa fa-save me-2" v-if="!processing"></i>
              Save and Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
