<script>
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import gmapsInit from '@/gmaps';

export default {
  components: {
  },
  props: {
    address: {
      type: Object,
    },
    editable:{
       type: Boolean
    }
  },
  async mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });

    const google = await gmapsInit();
    this.google = google;

    if(this.address.lat && this.address.lng) {
      this.initMap({lat:this.address.lat, lng: this.address.lng});
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.initMap({lat: position.coords.latitude, lng: position.coords.longitude})
        }, () => this.initMap(), {timeout:10000});
      } else {
        this.initMap();
      }
    }
    
  },
  beforeUnmount() {
    if(this.marker) {
      this.google.maps.event.clearListeners(this.marker);
    }
  },
  data() {
    return {
      pAddress: JSON.parse(JSON.stringify(this.address))
    };
  },
  watch: {
    pAddress: {
      handler(newValue) {

        if(!newValue.lat || !newValue.lng){
          this.marker.setVisible(false);
         
        } else {
          this.marker.setVisible(true);
        
          this.marker.visible = true;
          var latlng = new this.google.maps.LatLng(newValue.lat, newValue.lng);
          this.marker.setPosition(latlng);
          this.map.setCenter(latlng);
        }
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true
    }
  },
  methods: {
    initMap(center) {
      if(!center) {
        center = { lat: this.address.lat || 50.4501, lng: this.address.lng || 30.5234 };
      }
      let map = new  this.google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 16,
      });
    
      this.map = map;

      const uluru = { lat: this.address.lat || center.lat, lng: this.address.lng || center.lng };
      // The marker, positioned at Uluru
      const marker = new this.google.maps.Marker({
        position: uluru,
        map: map,
        draggable:true,
      });
      this.marker = marker;
      marker.addListener("dragend", this.markerDragend);
    },
    markerDragend(e) {
      this.pAddress.lat = e.latLng.lat();
      this.pAddress.lng = e.latLng.lng();
    },
    save() {
      this.$emit('save', { lat: this.pAddress.lat, lng: this.pAddress.lng, address: this.pAddress.address });
      this.close();
    },
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
  }
};
</script>

<template>
  <div>
    <div
      class="modal"
      ref="modal"
    >
      <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-4">
            
              <span v-if="editable">  {{$t('common.address')}}</span>
              <span v-if="!editable">  {{address.address}}</span>
            </h5>
          
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="editable">
              <div class="row mb-3">
                <label class="col-lg-3">{{$t('common.address')}}</label>
                <div class="col-lg-9">
                  <input class="form-control" v-model="pAddress.address"/>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3">{{$t('common.latitude')}}</label>
                <div class="col-lg-9">
                  <input class="form-control" v-model.number="pAddress.lat"/>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3">{{$t('common.longitude')}}</label>
                <div class="col-lg-9">
                  <input class="form-control" v-model.number="pAddress.lng"/>
                </div>
              </div>
            </div>
            <div ref="map" style="height:400px"></div>
          </div>  
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              @click="close"
            >
              {{ $t("common.closeButtonTitle") }}
            </button>
            <button 
              type="button" 
              class="btn btn-primary"
              @click="save"
              v-if="editable"   
            >
              {{ $t("common.saveButtonTitle") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
