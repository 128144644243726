<script>
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Status from './status.vue'
import moment from 'moment-timezone';

export default {
  components: {
    Status
  },
  props: {
    reminder:{
       type: Object,
       required: true
    }
  },
  
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
   ...mapGetters('reminders', {
    
    }),
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    ...mapMutations('processes', {
      editProcess: 'SET_EDITED_PROCESS'
    }),
    ...mapActions('reminders', {
      snoozeReminder: 'snoozeReminder',
      dismissReminder: 'dismissReminder'
    }),
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    async snooze(amount, unit) {
      this.processing = true;
      const snoozedTill = moment().add(amount, unit);
      await this.snoozeReminder({ till: snoozedTill, id: this.reminder.id });
      this.close();
      this.processing = false;
    },
    async dismiss() {
      this.processing = true;
      this.$forceUpdate();
      await this.dismissReminder({ id: this.reminder.id });
      this.close();
      this.processing = false;
    }
  }
};
</script>

<template>
  <div
    class="modal reminder-modal"
    ref="modal"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
             <span v-if="reminder.number" class="me-2">
              # {{reminder.number}}
            </span>
            <span class="me-2">Reminder</span><span><status :reminder="reminder"></status></span>
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-info p-3">
            <p>
              {{reminder.subject}}
            </p>
              
            <footer class="blockquote-footer mb-0 mt-1"  v-if="reminder.text">
              {{reminder.text}}
            </footer>
          </div>
          <div class="row">
            <div class="col-lg-8">
              
              
              
              <div class="mb-2 mt-4 row">
                <label class="h6">User's reactions</label>
                <div class="table-responsive">
                  <table class="table table-borderless mb-0">
                    <thead class="table-light">
                      <tr>
                        <th class="col-4">User</th>
                        <th class="col-4">Snoozed Till</th>
                        <th class="col-4">Dismissed At</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="remUser in reminder.users" :key="remUser.id">
                        <td class="col-4">
                          {{remUser.user.name}}
                        </td>
                        <td class="col-4">
                          <span v-if="remUser.snoozedTill">{{this.$filters.timeAndDate(remUser.snoozedTill)}}</span>
                        </td>
                        <td class="col-4">
                          <span v-if="remUser.dismissedAt">{{this.$filters.timeAndDate(remUser.dismissedAt)}}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <dl class="mt-2">
                 <dt>Date & Time</dt>
                <dd>
                  {{this.$filters.dateOnly(reminder.date) + ' ' + this.$filters.formatTimeOnly(reminder.time)}}
                </dd>
                <dt v-if=" reminder.process">Process</dt>
                <dd v-if=" reminder.process">
                  <a href="javascript:void(0)" v-if=" reminder.process" @click="editProcess({process:reminder.process})">
                    <b>#{{reminder.process.number}}</b> <span>{{reminder.process.processType.name}}</span>  [<state-name :state="reminder.process.state"></state-name>]
                  </a> 
                </dd>
                <dt v-if=" reminder.process && reminder.contact">Process</dt>
                <dd v-if=" reminder.process && reminder.contact">
                  <router-link  :to="{name:'contact-details', params: { id: reminder.process.contact.id }}">
                    {{reminder.process.contact.name}}
                  </router-link>
                </dd>
                <dt v-if=" reminder.contact">Contact</dt>
                <dd v-if=" reminder.contact">
                  <router-link  :to="{name:'contact-details', params: { id: reminder.contact.id }}">
                    {{reminder.contact.name}}
                  </router-link>
                </dd>
                <dt>Created At</dt>
                <dd>{{this.$filters.timeAndDate(reminder.createdAt)}}</dd>
                 <dt>Created By</dt>
                <dd>{{reminder.createdBy.name}}</dd>
              </dl>
            </div>
          </div>
         
        </div>
        <div class="modal-footer">
          <div class="col-auto">
           
          </div>
          <div class="col text-end">
             <button
              type="button"
              class="btn btn-light me-2"
              @click="close"
            >
              Close
            </button>
            <i class="fa fa-spin fa-spinner font-size-20" v-if="processing"></i>
            <div class="dropdown d-inline-block me-2" v-if="reminder.allowSnooze && !processing">
              <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown"> 
                Snooze <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(15, 'minutes')">15 Minutes</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(30, 'minutes')">30 Minutes</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(45, 'minutes')">45 Minutes</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(1, 'hours')">1 Hour</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(2, 'hours')">2 Hours</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(4, 'hours')">4 Hours</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(8, 'hours')">8 Hours</a>
                <a class="dropdown-item ps-2" href="javascript:void(0)" @click="snooze(1, 'days')">1 Day</a>
              </div>
            </div>
            <button
              v-if="reminder.allowDismiss  && !processing"
              type="button"
              class="btn btn-primary"
              @click="dismiss"
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
