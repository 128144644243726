<script>
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { convertFieldToDto, isCustomFieldDtoEmpty } from '@/helpers'
import { parseErrors} from "@/helpers"
import Swal from 'sweetalert2'
import Comments from './comments.vue'
import FileUpload from './file-upload';
import Files from './files';
import Changes from './changes';
import StatusColor from '../directories/status-color.vue';
import RelatedDirectory from './related-directory.vue';
import Tags from '@/components/tags'
import http from '@/app-http'
//import Camera from "simple-vue-camera";

export default {
  components: {
    Comments,
    FileUpload,
    Files,
    Changes,
    StatusColor,
    RelatedDirectory,
    Tags,
    //Camera
  },
  props: {
    input:{
       type: Object,
       required: true
    },
    mode:{
       type: String,
       required: true
    },
    template:{
       type: Object,
       required: false
    },
    transformation: {
      type: Object,
      required: false
    },
    closeOnSave: {
      type: Boolean,
      required: false
    },
    // beforeSave: {
    //   type: Function,
    //   required: false
    // }
  },
  data() {
    return {
      accessDenied: false,
      printing: false,
      errors: [],
      loading: false,
      i_mode: this.mode,
      i_input: this.input,
      commentSending: false, 
      activeTab: '',
      newComment: '',
      file: null,
      uploading: false,
      fieldEditing: false,
      executedDataTransformation: null,
      trelloProcessing: false,
      extButtonProgress: {

      },
      item: {
       
      }
    };
  },
  created(){
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  watch: {
    errors: {
      deep: true,
      // immediate: true,
      handler: function (nval, oval) {
        console.log('érrors watch', {nval, oval});
        if(nval !== oval && nval.length > 0) {
          console.log('érrors watch 2', {nval, oval});
          this.$nextTick(() =>{
            window.setTimeout( () => {
              console.log('érrors watch 3', {refs: this.$refs});
              if(this.$refs.errorList) {
              this.$refs.errorList.scrollIntoView(true);
            }
            }, 500);
           
          });
        }
      }
    },
    item:  {
      deep: true,
      // immediate: true,
      handler: function () {
        
        if(this.stopWatching) return;
        if(this.i_mode === 'edit') {
          this.executeRule('onItemChanged');
        }
      }
    }
  },
  computed :{
    ...mapGetters('rules', {
      allDataTransformation: 'dataTransformation'
    }),
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
      token: 'token'
    }),
    ...mapGetters('directoryItems', {
      processing: 'processing',
      deleting: 'deleting',
      allTags: 'tags'
    }),
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      
      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
      })
    },
    dataTransformation() {
      var res = this.allDataTransformation.filter(x => x.sourceDirectoryId === this.directoryId && !x.disabled) || [];
      if(!this.currentUsageProfile)
        return [];

      if(!this.currentUser.isAdmin) { 
        res = res.filter(x => {
          const dirAccess = this.currentUsageProfile.directoryAccess.find(a => a.directoryId === x.targetDirectoryId);
          if(!dirAccess) {
            return false;
          }
          return x.type === 'Create' ?  dirAccess.allowCreate : dirAccess.allowUpdate;
        });
      }
      return res;
    },
    tags() {
      return (this.allTags || {})[this.directoryId] || [];
    },  
    title () {
      return (this.isNew ? this.$t('common.newRecord') : this.$t('common.editRecord')) + ' [' + ((this.directory || {}).name || '') + ']'
    },
    directory () {
      return this.directories.find(x => x.id === this.directoryId);
    }, 
    status () {
      if(this.directory.type === 'Process') {
        const statusDef = this.directory.statusList.find(x => x.id === this.item.status);
        if(statusDef){
          return statusDef;
        }
        return this.directory.statusList[0];
      }
      return null;
    },  
    directoryId() {
      return this.input.directoryId;
    },
    isNew () {
      return !(this.i_input.id) ? true : false;
    },
    customFields() {
      return ((this.directory || {}).fields || []).filter(x => !x.hidden);
    },
    allowReadChanges() {
      if(this.currentUser.isAdmin)
        return true;

      if(!this.currentUsageProfile)
        return false;
      
      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowReadChanges;
    },
    allowReadComment() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowReadNote;
    },
    allowDeleteComment() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowDeleteNote;
    },
    allowUpdateComment() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowUpdateNote;
    },
    allowAddComment() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowAddNote;
    },
    allowAddFile() {
     if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowAddFile;
    },
    allowReadFile() {
     if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowReadFile;
    },
    allowEdit() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowUpdate;
    },
    allowDelete() {
      if(this.currentUser.isAdmin)
        return true;
      
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowDelete;
    },
    relatedDirectoryFields() {
      let res = [];
      this.directories.forEach(dir => {
        const relFields = dir.fields.filter(x => x.directoryId === this.directoryId && x.type === 'Directory' && x.showOnDirectoryForm);
        relFields.forEach(field => {
          res.push({ field, dir });
        });
      });
     
      return res;
    },
    wide() {
      return this.directory.filesEnabled || this.directory.commentsEnabled || this.relatedDirectoryFields.length > 0;
    }
  },
  methods: {
    ...mapMutations('directoryItems', {
      showDataTransformation: 'SHOW_DATA_TRANSFORMATION_FORM'
    }),
    ...mapActions('directoryItems', {
      create: 'create',
      update: 'update',
      delete: 'delete',
      loadById: 'loadById',
      addComment: 'addComment',
      addFile: 'addFile'
    }),
    uploadPhoto(e) {
      
      const fileAux = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        var img = new Image();
        img.src = e.target.result;
        img.onload  = () => {
          var MAX_WIDTH = 1000;
          var MAX_HEIGHT = 1000;
          var width = img.width;
          var height = img.height;
          // Change the resizing logic
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }
      
          var canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          
          canvas.toBlob((blob)=>{
            
            const response = {
              file: fileAux,
              fileName: fileAux.name,
              fileBlob: blob,
              fileType: fileAux.type
            }
            this.file = response;
            this.uploadFile();
          }, 'image/jpeg');
        }
            
             
        };
        fileReader.readAsDataURL(fileAux);
      //fileReader.readAsArrayBuffer(fileAux) 
    },
    makePhoto() {
      this.$refs.cameraImageFileInput.click();
    },
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    executeFieldExtender(extBtn) {
      let obj = this.$converters.toObject({ dir: this.directory, model: JSON.parse(JSON.stringify(this.item)) });
      extBtn.click(obj, {updater: this.updateFromObject, progress: (v) => {
        const a = this.extButtonProgress;
        let o = {...a};
        o[extBtn.id] = v;
        this.extButtonProgress = o;
      
      }});
    },
    updateFromObject(obj) {
      
      this.item = this.$converters.fromObject({ dir: this.directory, obj } );
    },
    executeRule (ruleName) {
      this.stopWatching = true;
      try {
        let obj = this.$converters.toObject({ 
          dir: this.directory, 
          model: JSON.parse(JSON.stringify(this.item))
        });
        obj = this.$formRules()[ruleName]({
          dir: this.directory, 
          obj,
          data: this.rulesData || {} });
        this.updateFromObject(obj);
      } finally {
        this.$nextTick( () =>{
          this.stopWatching = false;
        }); 
      }
    },
    async init() {
      await this.reload();

      if(this.directory.commentsEnabled) {
        this.activeTab = 'comments';
      } else if(this.directory.filesEnabled && !this.isNew) {
        this.activeTab = 'files';
      }

      if(this.isNew && this.i_mode === 'edit') {
        this.executeRule('onItemNew');
        this.executeRule('onItemChanged');
      }

      if(this.template) {
        const templateKeys = Object.keys(this.template.customFields);
        templateKeys.forEach(key => {
          if(Array.isArray(this.item.customFields[key]) && this.template.customFields[key]) {
            this.item.customFields[key] = [...this.item.customFields[key],...this.template.customFields[key]];
          } else {
            this.item.customFields[key] = this.template.customFields[key];
          }
        });
      
      }
    },
    onSuggestSelected(obj) {
      console.log('onSuggestSelected', obj);
      this.i_input = obj;
      this.reload();
    },
    async reload() {
      if(this.i_input.id) {
        this.loading = true;
        this.i_input = await this.loadById({ directoryId: this.i_input.directoryId, id: this.i_input.id });
        if(!this.i_input) {
          this.accessDenied = true;
        }
        this.loading = false;
      }
      this.populate(this.i_input);

      const onLoad = this.$formRules().onLoad;
      if(onLoad) {
        this.rulesData = await onLoad({ dir: this.directory });
      }
    },
    populate(source) {
      
      const {
        id,
        number,
        seq,
        directoryId,
        customFields,
        tags,
        status,
        dataTransformation
      } = JSON.parse(JSON.stringify(source));

      this.executedDataTransformation = dataTransformation;

      if(this.i_mode === 'view') {
        this.item = {
          id,
          number,
          seq: seq || 0,
          directoryId,
          tags,
          status: status || (this.directory.type === 'Process' ? this.directory.statusList[0].id : null),
          customFields: customFields || {}
        };
      } else {
        this.item = {
          id,
          number,
          seq: seq || 0,
          directoryId,
          tags,
          status: status || (this.directory.type === 'Process' ? this.directory.statusList[0].id : null),
          customFields: customFields || {}
        };
      }
     
    },
    async submit () {
      if(this.fieldEditing) {
         Swal.fire({ text: this.$t('common.editedFieldsExists'),  icon: "warning",});
        return;
      }

      this.executeRule('onItemChanged');

      this.errors = [];
      const customFields = convertFieldToDto(((this.directory || {}).fields || []), this.item.customFields);
   
      const dto = {
        seq: this.item.seq,
        status: this.item.status,
        newComment: this.newComment,
        customFields: customFields,
        tags: this.item.tags,
        dataTransformation: this.transformation
      };

      if(this.directory.type === 'Process') {
        if(this.i_input.status !== this.item.status || this.isNew) {
          var prevStatus = this.directory.statusList.find(x => x.id === this.i_input.status);
          var nextStatus =  this.directory.statusList.find(x => x.id === this.item.status);
          if(this.directory.commentsEnabled && ((prevStatus || {}).requiredCommentToLeave || nextStatus.requiredCommentToEnter)) {
            if(!this.newComment) {
              this.errors = [this.$t('common.commentRequired')];
              return;
            }
          }
        }
      
        var statusRequiredFields = this.status.fields.filter(x => x.required);
        statusRequiredFields.forEach(reqField => {
          const fieldDef = this.directory.fields.find(x => x.id === reqField.field);
          if(!fieldDef) return;

          const f = customFields[reqField.field];
          if(!f || isCustomFieldDtoEmpty(f)) {
            this.errors = [...this.errors, this.$t('common.field') + ' ' + fieldDef.name + ' ' + this.$t('common.isRequired')];
          }
        });
      }

      if(this.errors.length > 0) {
        return;
      }
      
      try {
        let resp;
        if(this.isNew) {
          resp = await this.create({ directoryId: this.item.directoryId, dto});
        } else {
          resp = await this.update({ directoryId: this.item.directoryId, id: this.item.id, dto});
        }
        this.$toast.open({message: this.$t('common.savedText')});
        this.newComment = null;
        
        this.$emit("save", resp);

        if(this.mode === 'view' || this.isNew) {
          if(this.closeOnSave) {
            this.close();
          } else {
            this.i_mode = 'view';
            this.i_input = resp;
            this.populate(resp);
          }
          
        } else {
          this.close();
        }
        
       
      } catch(error) {
        this.errors = parseErrors(error);
      }
    },
    onDelete( ) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          this.delete({directoryId: this.item.directoryId, id: this.item.id}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
            this.close();
          }).catch((error)=>{
            const errors = parseErrors(error);
            Swal.fire(this.$t('common.cannotDelete'), errors[0], "warning");
          });
        }
      });
    },
    edit() {
      this.i_mode = 'edit';
      this.populate(this.i_input);
    },
    async sendComment() {
      if(this.i_mode === 'view') {
        this.commentSending = true;
        await this.addComment({directoryId:this.directoryId, itemId: this.i_input.id, text: this.newComment });
        this.commentSending = false;
        this.newComment = null;
      }
    },
    async uploadFile() {
    
      const dto = {
        fileBlob: this.file.fileBlob,
        mimeType: this.file.mimeType,
        fileName: this.file.fileName
      };
      this.uploading = true;
      await this.addFile({ directoryId:this.directoryId, itemId: this.i_input.id, file: dto });
      this.file = null;
      this.uploading = false;
    },
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.directory.fields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = directory.fields.find(x => x.id === directoryRelatedFieldId);
          return directoryRelatedField;
        }
      }
      return null;
    },
    getRelatedFieldValue(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.directory.fields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const dirValue = this.item.customFields[directoryFieldId];
           
          if(dirValue) {
            return dirValue.customFields[directoryRelatedFieldId];
          }
        }
      }
      return null;
    },
    isFieldHidden(field) {
      if(field.hidden) return true;

      let hidden = false;

      if(this.directory.type === 'Process' && this.status) {
         var statusField = this.status.fields.find(x => x.field === field.id);
         if(statusField) {
          hidden = statusField.hidden;
         }
      }
     
      if(hidden) return true;

      if(this.currentUser.isAdmin)
        return false;
        
      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.hiddenFields && dirAccess.hiddenFields.indexOf(field.id) >= 0;
    },
    isFieldReadonly(field) {
      if(this.directory.type !== 'Process' || !this.status) {
        return false;
      }
      var statusField = this.status.fields.find(x => x.field === field.id);
      if(!statusField) return false;
      return statusField.readOnly;
    },
    async print(responseType) {
      this.printing = true;
      let file = null;
      try {
        const fileHandles = await window.showOpenFilePicker({
          types: [
            {
              description: 'MS Word (Template)',
              accept: {
                'word/*': ['.docx']
              }
            },
          ],
        });
        if(fileHandles.length > 0) {
            const fileHandle = fileHandles[0];
            file = await fileHandle.getFile();
        } else {
          this.printing = false;
          return;
        }
      } catch {
        this.printing = false;
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const blob = new Blob([fileReader.result], {
          // This will set the mimetype of the file
          type: file.type
        });
        const { item } = this;
        const formData = new FormData();
        formData.append('file', blob, file.fileName);
        formData.append('json', JSON.stringify({fileName: file.name, responseType:responseType}));
        const printingResponse = await http({
          method: 'post',
          responseType: 'blob', // important
          url:  `directories/${item.directoryId}/items/${item.id}/print`,
          data: formData,
          headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          }
        });
        const contentDisposition = printingResponse.headers['content-disposition'];
        let filename = contentDisposition.split('filename=')[1];
        const url = window.URL.createObjectURL(new Blob([printingResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        this.printing = false;
      };
      fileReader.readAsArrayBuffer(file);    
    },
    async exportToTrello() {
      if(this.trelloProcessing) return;
      this.trelloProcessing = true;
      try {
        await http.post(`directories/${this.directoryId}/items/${this.i_input.id}/trello`)
        this.reload();
      } finally {
        window.setTimeout(() => {
          this.trelloProcessing = false;
        }, 1000);
      }
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable " 
      :class="{'modal-lg': !wide, 'modal-xl': wide}"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col">
            <h5 class="modal-title me-4">
              <b v-if="item.number" class="me-2">
                #{{item.number}}
              </b>
              {{title}}
              <i class="fa fa-spin fa-spinner ms-2" v-if="loading"></i>
            </h5>
            <div class="mt-1">
              <tags :tags="tags" :value="item.tags" @update="tags => item.tags = tags" :editable="i_mode === 'edit'"></tags>
            </div>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0" ref="errorList">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

          </div>

          <div v-if="accessDenied" class="alert mt-2 mb-2 alert-warning">
            {{$t('common.accessDenied')}}
          </div>
          
          <div :class="{'row': wide}">
            <div :class="{'col-lg-8': wide}">
             <div v-if="i_mode === 'view' && !loading">
                <div class="mb-3 row" v-if="directory.type === 'Process' && status">
                  <label class="mb-0 dir-item-field-label col-lg-3">{{$t('directory.fields.status')}}</label>
                  <div class="col-lg-9">
                    <status-color :color="status.color"></status-color>
                    {{status.name}}
                  </div>
                </div>

                <div class="mb-3 row" v-if="directory.type === 'Process' && i_mode === 'view' && directory.id === '1ee317c47fb34cb3888f0788ccf19d0b'">
                  <label class="mb-0 dir-item-field-label col-lg-3">Trello</label>
                  <div class="col-lg-9">
                    <div class="input-group" v-if="!item.customFields['a673c8f0-2b99-11ed-a001-a985773b5bd2']">
                      <div class="form-control text-truncate text-nowrap">
                        <a href="javascript:void(0)" class="text-muted">
                        Нет картоки
                        </a>
                      </div>
                      <button 
                        class="btn btn-outline-secondary" 
                        type="button" 
                        @click="exportToTrello()" 
                        :disabled="trelloProcessing"
                        
                      >
                        <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="trelloProcessing"/>
                        Створити картку
                      </button>
                    </div>
                    <div class="input-group" v-if="item.customFields['a673c8f0-2b99-11ed-a001-a985773b5bd2']">
                      <div class="form-control text-truncate text-nowrap">
                        <a :href="item.customFields['a673c8f0-2b99-11ed-a001-a985773b5bd2']">
                          Перейти у трелло
                        </a>
                      </div>
                      <button 
                        class="btn btn-outline-secondary" 
                        type="button" 
                        @click="exportToTrello()" 
                        :disabled="trelloProcessing"
                       
                      >
                        <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="trelloProcessing"/>
                        Створити картку
                      </button>
                    </div>
                  </div>
                </div>

                <template v-for="customField in customFields.filter(x => !isFieldHidden(x))" :key="customField.id">
                  <template v-if="customField.type === 'DirectoryRelatedField' && getRelatedFieldValue(customField.directoryFieldId, customField.directoryRelatedFieldId)">
                    <div 
                      :style="{'background-color': (customField.bgColorEnabled ? customField.bgColor : ''), 'color': (customField.fgColorEnabled ?customField.fgColor : '')}" 
                      :class="customField.cssClass"
                    >
                      <div class="pb-3" :title="customField.name" :class="{'row': directory.inlineLabel || customField.inlineLabel}">
                        <label class="mb-0 dir-item-field-label" :class="{'col-lg-3': directory.inlineLabel || customField.inlineLabel}">{{customField.name}}</label>
                        <div :class="{'col-lg-9': directory.inlineLabel || customField.inlineLabel}">
                          <custom-field 
                            :def="getRelatedFieldDef(customField.directoryFieldId, customField.directoryRelatedFieldId)" 
                            :value="getRelatedFieldValue(customField.directoryFieldId, customField.directoryRelatedFieldId)" 
                            @changed="reload"
                          >
                          </custom-field>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="customField.type !== 'DirectoryRelatedField'">
                    <div 
                    :style="{'background-color': (customField.bgColorEnabled ? customField.bgColor : ''), 'color': (customField.fgColorEnabled ?customField.fgColor : '')}" 
                    :class="customField.cssClass"
                    >
                     
                      <div class="pb-3" :title="customField.name" v-if="item.customFields[customField.id]" :class="{'row': directory.inlineLabel || customField.inlineLabel}">
                        <label class="mb-0 dir-item-field-label" :class="{'col-lg-3': directory.inlineLabel || customField.inlineLabel}">{{customField.name}}</label>
                        <div :class="{'col-lg-9': directory.inlineLabel || customField.inlineLabel}">
                          <custom-field 
                            :def="customField" 
                            :value="item.customFields[customField.id]" 
                            @changed="reload"
                          >
                          </custom-field>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
              <div v-if="i_mode === 'edit'  && !loading">
                
                <div class="mb-3 row" v-if="directory.type === 'Process'" >
                  <label class="col-form-label dir-item-field-label col-lg-3">{{$t('directory.fields.status')}}</label>
                  <div class="'col-lg-9">
                    <select class="form-control" v-model="item.status">
                      <option v-for="s in directory.statusList" :key="s.id" :value="s.id">
                      {{s.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-3" 
                  :class="{'row': directory.inlineLabel || customField.inlineLabel}"
                  v-for="customField in customFields.filter(x => !isFieldHidden(x))" 
                  :key="customField.id" 
                >
                  <label class="col-form-label dir-item-field-label" :class="{'col-lg-3': directory.inlineLabel || customField.inlineLabel}">{{customField.name}}</label>
                  <div 
                    :class="{'col-lg-9': directory.inlineLabel || customField.inlineLabel}" 
                    v-if="!isFieldReadonly(customField) && customField.type !== 'DirectoryRelatedField'"
                  >
                    <div 
                      class="mb-1 text-end" 
                      v-if="$fieldExtender(directory, customField).editButtons.length > 0" 
                    >
                      <template v-for="(extBtn, $index) in ($fieldExtender(directory, customField).editButtons)" :key="$index">
                        <button class="ms-2" :class="extBtn.cls" @click="executeFieldExtender(extBtn)">
                          <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin me-1" v-if="extButtonProgress[extBtn.id]"/>{{extBtn.label}}
                        </button>
                      </template>
                    </div>
                    <custom-field-edit 
                      @fieldEditing="(v) => fieldEditing = v"
                      :def="customField" 
                      :directory-id="directoryId"
                      @reload="onSuggestSelected"
                      :value="item.customFields[customField.id]" 
                      @update="(v) => item.customFields[customField.id] = v"
                      
                    >
                    </custom-field-edit>
                  </div>
                 
                  <div class=" pt-2" v-if="isFieldReadonly(customField) ||  customField.type === 'DirectoryRelatedField'" :class="{'col-lg-9': directory.inlineLabel || customField.inlineLabel}" >
                    <template v-if="customField.type === 'DirectoryRelatedField' && getRelatedFieldValue(customField.directoryFieldId, customField.directoryRelatedFieldId)">
                      <custom-field 
                        :def="getRelatedFieldDef(customField.directoryFieldId, customField.directoryRelatedFieldId)" 
                        :value="getRelatedFieldValue(customField.directoryFieldId, customField.directoryRelatedFieldId)" 
                        @changed="reload"
                      >
                      </custom-field>
                    </template>
                    <custom-field
                      v-else 
                      :def="customField" 
                      :value="item.customFields[customField.id]" 
                    >
                    </custom-field>
                  </div>
                </div>
              </div>
            </div>
            <div :class="{'col-lg-4': wide}" v-if="wide">
              <ul class="nav nav-tabs  " role="tablist">
                <li class="nav-item" v-if="directory.commentsEnabled && allowReadComment" >
                  <a
                    class="nav-link"
                    :class="{'active': activeTab === 'comments'}"
                    data-bs-toggle="tab"
                    href="#comments"
                    role="tab"
                    @click=" activeTab = 'comments'"
                  >
                    <span class="">{{$t('common.comments')}}</span>
                  </a>
                </li>
                <li class="nav-item" v-if="directory.filesEnabled && allowReadFile && !isNew" >
                  <a
                    class="nav-link"
                    :class="{'active': activeTab === 'files'}"
                    data-bs-toggle="tab"
                    href="#files"
                    role="tab"
                    @click=" activeTab = 'files'"
                  >
                    <span class="">{{$t('common.files')}}</span>
                  </a>
                </li>
                <template v-for="relField in relatedDirectoryFields" :key="relField.field.id">
                  <li  v-if="!isNew">
                    <a
                      class="nav-link"
                      :class="{'active': activeTab === relField.field.id}"
                      data-bs-toggle="tab"
                    
                      role="tab"
                      @click=" activeTab = relField.field.id"
                    >
                      <span class="">{{relField.field.nameOnDirectoryForm}}</span>
                    </a>
                  </li>
                </template>
                <li class="nav-item" v-if="directory.changesTrackingEnabled && allowReadChanges && !isNew" >
                  <a
                    class="nav-link"
                    :class="{'active': activeTab === 'changes'}"
                    data-bs-toggle="tab"
                    href="#changes"
                    role="tab"
                    @click=" activeTab = 'changes'"
                  >
                    <span class="">{{$t('common.changes')}}</span>
                  </a>
                </li>
              </ul>
              
              <div class="tab-content ">
                <div class="tab-pane" id="comments" role="tabpanel" v-if="directory.commentsEnabled && allowReadComment" :class="{'active': activeTab === 'comments'}">
                  <div class=" mt-3 mb-3" v-if="i_mode === 'edit' && allowAddComment">
                    <div class="">
                      <div>
                        <textarea 
                          rows="3" 
                          class="form-control " 
                          :placeholder="$t('common.yourComment')" 
                          :readonly="commentSending" 
                          v-model="newComment"
                          v-on:keyup.enter="sendComment"
                        >
                        </textarea>
                      </div>
                    </div>
                    <!-- <div class="text-end mt-2" v-if="i_mode === 'view'">
                      <button type="button" class="btn btn-light btn-sm  w-sm text-truncate ms-2" @click="sendComment" :disabled="!newComment || commentSending"> 
                        {{$t('common.sendBtnTitle')}} 
                        <i class="bx bx-send ms-2 align-middle" v-if="!commentSending"></i>
                        <i class="fa fa-spin fa-spinner ms-2 align-middle" v-if="commentSending"></i>
                      </button>
                    </div> -->
                  </div> 
                  <div v-if="allowReadComment">
                    <comments :item-id="i_input.id" :directory-id="directoryId" v-if="!isNew">
                    </comments>
                  </div>
                  
                </div>
                <div class="tab-pane" id="files" role="tabpanel" v-if="directory.filesEnabled && allowReadFile && !isNew" :class="{'active': activeTab === 'files'}">
              
                  <div class=" mt-3 mb-3 text-end" v-if="allowAddFile">
                    <i v-if="uploading" class="fa fa-spin fa-spinner font-size-24"></i>

                    <input 
                      style="display: none" 
                      type="file" 
                      accept="image/*;capture=camera" 
                      capture  
                      ref="cameraImageFileInput" 
                      @change="uploadPhoto"
                    />
                    <button class="btn btn-light btn-sm me-2" v-if="!uploading && isMobile" @click="makePhoto">
                      <font-awesome-icon icon="fa-solid fa-camera" /> 
                    </button>
                    <file-upload v-if="!uploading" v-model="file" @ready="uploadFile"/>
                  </div>

                  <files :item-id="i_input.id" :directory-id="directoryId" v-if="!isNew">
                  </files>

                </div>

                <template v-for="relField in relatedDirectoryFields" :key="relField.field.id">
                
                  <div class="tab-pane"  role="tabpanel"  :class="{'active': item.id && activeTab ===relField.field.id}">
                    <related-directory :item="item" :rel-field="relField.field" :rel-directory="relField.dir" v-if="item.id && activeTab ===relField.field.id "></related-directory>
                  </div>
                </template>
                <div class="tab-pane" id="changes" role="tabpanel" v-if="directory.changesTrackingEnabled && allowReadChanges && !isNew" :class="{'active': activeTab === 'changes'}">
                  <changes :item="i_input" :directory-id="directoryId" v-if="!isNew">
                  </changes>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <div class="modal-footer">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-light me-2"
              @click="close"
            >
              {{$t('common.closeButtonTitle')}}
            </button>
          </div>
          <div class="col text-end">
           
            <div class="btn-group  me-2"  v-if="i_mode === 'view' && allowEdit && allowDelete">
              <a href="javascript:void(0)" 
                c class="btn btn-secondary"
                 @click="edit"
                >
                <font-awesome-icon icon="fa-solid fa-pen" />  {{$t('common.editButtonTitle')}}
              </a>
              <div class="dropdown btn btn-secondary px-1 btn-sm" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  role="button" >
                <a class="dropdown-toggle text-white " href="javascript:void(0)" >
                  <i class="bx bx-dots-vertical-rounded font-size-16 mt-1"></i>
                </a>
                
              </div>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item px-2 text-danger" href="javascript:void(0)" @click="onDelete" >
                  <i class="fa fa-spin fa-spinner me-2" v-if="deleting === i_input.id"></i>
                  <i class="fa fa-trash me-2" v-if="!deleting"></i>
                  {{$t('common.deleteButtonTitle')}}
                </a>
              </div>
              
            </div>

           <button 
              v-if="i_mode === 'view' && allowEdit && !allowDelete"
              type="button" 
              class="btn btn-secondary me-2" 
              @click="edit"
            >
               <font-awesome-icon icon="fa-solid fa-pen" />  {{$t('common.editButtonTitle')}}
            </button>

            <template v-if="(dataTransformation.length > 0) && i_mode === 'view'">
              <div class="dropdown btn btn-secondary  me-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  role="button" >
                <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" />
                 {{$t('common.actionsButtonTitle')}}
              </div>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item px-2" href="javascript:void(0)" v-for="rule in dataTransformation" :key="rule.id" 
                @click="showDataTransformation({ ruleId: rule.id, sourceId: i_input.id, onClose: reload })">
                  {{rule.name}} 
                </a>
              </div>
            </template>

            <button  
              v-if="i_mode === 'view'"
              type="button" 
              class="dropdown btn btn-secondary"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  role="button" 
             
            >
              <font-awesome-icon icon="fa-solid fa-spinner" class="fa-svg-spin" v-if="printing"/>
              <font-awesome-icon icon="fa-solid fa-print" v-if="!printing"/> 
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">{{$t('common.printTitle')}}</h6>
              <a class="dropdown-item px-2" href="javascript:void(0)"  @click="print('docx')">
                <font-awesome-icon icon="fa-solid fa-file-word" /> <span class="ms-2">{{$t('common.printToWord')}}</span>
              </a>
              <a class="dropdown-item px-2" href="javascript:void(0)"  @click="print('pdf')">
                <font-awesome-icon icon="fa-solid fa-file-pdf" /> <span class="ms-2">{{$t('common.printToPDF')}}</span>
              </a>
            </div>
            <button 
              v-if="i_mode === 'edit'  && allowEdit"
              type="button" 
              class="btn btn-primary" 
              :disabled="processing || deleting" 
              @click="submit"
            >
              <i class="fa fa-spin fa-spinner me-2" v-if="processing"></i>
              <i class="fa fa-save me-2" v-if="!processing"></i>
              {{$t('common.saveButtonTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
