<script>

import { mapGetters, mapActions } from 'vuex';


export default {
  components: {
   
  },
  props: {
    directoryId: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  async mounted(){
    const { directoryId, itemId } = this;
    this.loading = true;
    await this.loadChanges({directoryId, itemId});
    this.loading = false;
  },
  computed :{
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs'
    }),
    ...mapGetters('directoryItems', {
      changesItems: 'changes'
    }),
    itemId() {
      return this.item.id;
    },
    directory () {
      return this.directories.find(x => x.id === this.directoryId);
    }, 
    changes() {
      return this.changesItems[this.itemId] || [];
    }
  },
  methods: {
    ...mapActions('directoryItems', {
      loadChanges: 'loadChanges'
    })
  }
};
</script>

<template>
  <div>
    
    <div class="list-group mt-2">
      <div class="list-group-item" v-for="trackingItem in changes" :key="trackingItem.id">
        <div class="row">
          <div class="col">
            <span v-if="trackingItem.insertedBy">{{trackingItem.insertedBy.name}}</span>
            
          </div>
          <div class="col-auto text-muted">
            {{$filters.timeAndDate(trackingItem.insertedAt)}}
          </div>
        </div>
        <ul class="mt-2" v-for="(item, index) in trackingItem.data.items" :key="trackingItem.id + index">
          <li v-if="item.type === 'StatusChanged'">
            {{$t('changesTracking.statusWasChanged')}} <span v-if="item.fromStatus">{{$t('changesTracking.fromStatus')}} <b>{{item.fromStatus.name}}</b></span> <span v-if="item.toStatus">{{$t('changesTracking.toStatus')}} <b>{{item.toStatus.name}}</b></span>
          </li>
          <template v-if="item.type === 'TagAdded'">
            <li v-for="(tag, tagIndex) in item.tags" :key="trackingItem.id + index +  '' + tagIndex">
              {{$t('changesTracking.tagWasAdded')}}: <span class="badge badge-soft-dark"><font-awesome-icon icon="fa-solid fa-tag" class="me-1"/>{{tag}}</span>
            </li>
          </template>
          <template v-if="item.type === 'TagRemoved'">
            <li v-for="(tag, tagIndex) in item.tags" :key="trackingItem.id + index +  '' + tagIndex">
              {{$t('changesTracking.tagWasRemoved')}}: <span class="badge badge-soft-dark"><font-awesome-icon icon="fa-solid fa-tag" class="me-1"/>{{tag}}</span>
            </li>
          </template>
          <li v-if="item.type === 'FileAdded'">
            {{$t('changesTracking.fileWasAdded')}}: <b>{{item.newFieldValue}}</b>
          </li>
          <li v-if="item.type === 'FileRemoved'">
            {{$t('changesTracking.fileWasRemoved')}}: <b>{{item.oldFieldValue}}</b>
          </li>
          <li v-if="item.type === 'FieldChanged'">
            {{$t('changesTracking.field')}} <b>{{item.fieldName}}</b> {{$t('changesTracking.wasChanged')}}. 
            <dl class="row">
              <dt style="font-weight: normal;" class="col-lg-3" v-if="item.oldFieldValue">{{$t('changesTracking.oldValue')}}</dt>
              <dd style="font-weight: bold;" class="col-lg-9" v-if="item.oldFieldValue">{{item.oldFieldValue}}</dd>
              <dt style="font-weight: normal;" class="col-lg-3"  v-if="item.newFieldValue">{{$t('changesTracking.newValue')}}</dt>
              <dd style="font-weight: bold;" class="col-lg-9" v-if="item.newFieldValue">{{item.newFieldValue}}</dd>
            </dl>
          </li>
        </ul>
      </div>
      <div class="list-group-item list-group-item-primary" >
        <div class="row">
          <div class="col">
            {{item.createdBy.name}}
          </div>
          <div class="col-auto text-muted">
            {{$filters.timeAndDate(item.createdAt)}}
          </div>
        </div>
        <b class="mt-2">
          {{$t('common.recordWasCreated')}}
        </b>
      </div>
    </div>
  </div>
</template>
