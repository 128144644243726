<script>

import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2'

export default {
  components: {
   
  },
  props: {
    itemId: {
      type: String,
      required: true
    },
    directoryId: {
      type: String,
      required: true
    }
  },
   data() {
    return {
      text: null,
      editingNote: null,
      processing: false,
      loading: false,
      deleting: false
    };
  },
  async mounted(){
    const { directoryId, itemId } = this;
    this.loading = true;
   
    await this.loadComments({directoryId, itemId});
    this.loading = false;
  },
  computed :{
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs'
    }),
    ...mapGetters('directoryItems', {
      notesItems: 'comments'
    }),
    directory () {
      return this.directories.find(x => x.id === this.directoryId);
    }, 
    notes() {
      return this.notesItems[this.itemId] || [];
    },
    allowEdit() {
      if(this.currentUser.isAdmin)
        return true;

      if(!this.currentUsageProfile)
        return false;

      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowUpdateNote;
    },
    allowDelete() {
      if(this.currentUser.isAdmin)
        return true;

      if(!this.currentUsageProfile)
        return false;
      
      const dirAccess = this.currentUsageProfile.directoryAccess.find(x => x.directoryId === this.directoryId);
      if(!dirAccess) {
        return false;
      }
      return dirAccess.allowDeleteNote;
    },
  },
  methods: {
    ...mapActions('directoryItems', {
      loadComments: 'loadComments',
      updateComment: 'updateComment',
      deleteComment: 'deleteComment'
    }),
    async update() {
      if(this.editingNote && this.editingNote.text) {
        const { directoryId, itemId } = this;
        this.processing  = this.editingNote.id;
        await this.updateComment({directoryId, itemId, id: this.editingNote.id, text: this.editingNote.text});
        this.editingNote = null;
        this.processing  = false;
      }
    },
    startEdit(note){
      const { id, text } = note;
      this.editingNote = { id, text };
      this.$nextTick(() => {
      
        if(Array.isArray(this.$refs.noteEditor)) {
          this.$refs.noteEditor[0].focus();
        } else {
          this.$refs.noteEditor.focus();
        }
      } );
    },
    tryToDelete(id) {
      Swal.fire({
        title: this.$t('common.confirmationTitle'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('common.yesDeleteIt')
      }).then(result => {
        if (result.value) {
          const { directoryId, itemId } = this;
          this.deleting  = id;
          this.deleteComment({id: id, directoryId, itemId}).then(()=>{
            Swal.fire(this.$t('common.deletedTitle'), this.$t('common.deletedText'), "success");
             this.deleting  = false;
          });
        }
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="text-muted px-2 mb-4 pt-4" v-if="notes.length === 0">
      {{$t('common.noRecordsFound')}}
    </div> 
    <div 
      v-for="(note) in notes"
      :key="note.id"
      class="border px-3 py-2 mt-2"
      
    >
      <div class="d-flex align-items-start">
        <div class="flex-grow-1">
          <div class="d-flex">
            <div class="flex-1">
              <i class="fas fas fa-user-tie me-2 small"></i>{{note.createdBy.name}}
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 text-muted small">
          {{$filters.timeAndDate(note.createdAt)}}
        </div>
      </div>
      <div class=" mt-2" v-if="editingNote && editingNote.id === note.id">
        <div class="">
          <div class="border rounded mt-4">
            <div>
              <textarea 
                ref="noteEditor"
                rows="3" 
                class="form-control border-0 resize-none" 
                :placeholder="$t('common.yourComment')" 
                :readonly="processing" v-model="editingNote.text"
              >
              </textarea>
            </div>
          </div>

          <div class="mt-3">
            <button type="button" class="btn btn-light w-sm text-truncate ms-2  btn-sm" @click="editingNote = null" :disabled="!editingNote.text"> 
              {{$t('common.cancelBtnTitle')}} 
            </button>
            <button type="button" class="btn btn-primary w-sm text-truncate ms-2 float-end btn-sm" @click="update" :disabled="!editingNote.text || processing"> 
              <i class="fa fa-save ms-2 align-middle" v-if="!processing"></i>
              <i class="fa fa-spin fa-spinner ms-2 align-middle" v-if="processing"></i>
              {{$t('common.updateBtnTitle')}} 
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="!editingNote || editingNote.id !== note.id">
        <div class="col">
          <span class="" style="white-space: break-spaces;"> 
            {{note.text}}
          </span>
        </div>
        <div class="col-auto">
          <div class="ms-4">
            <ul class="list-inline product-review-link mb-0">
              <li class="list-inline-item" v-if="allowEdit || note.allowEdit">
                <a href="javascript:void(0)" 
                  class="text-primary"  
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  @click="startEdit(note)"
                >
                  <i class="bx bx-pencil font-size-16"></i>
                </a>
              </li>
              <li class="list-inline-item" v-if="allowDelete || note.allowDelete">
                <a
                  @click="tryToDelete(note.id)"
                  href="javascript:void(0);"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                  class="text-danger"
                  >
                  <i class="bx bx-trash-alt font-size-16" v-if="deleting !== note.id"></i>
                  <i class="fa fa-spin fa-spinner font-size-16" v-if="deleting === note.id"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" v-if="(editingNote || {}).id != note.id">
        <div class="col">
            <div class="text-muted mb-0 mt-1 small" v-if="note.updatedBy">Edited By {{note.updatedBy.name}} At {{$filters.timeAndDate(note.updatedAt)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
