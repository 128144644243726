<script>
export default {
  components: {  
    
  },
  props: {
    page: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    small: {
      type: Boolean
    }
  },
 
  data() {
    return {
      options: {
      offset: 4,
      previousText: '<<',
      nextText: '>>',
      alwaysShowPrevNext: true
     }
    }
  },
  computed:{
    lastPage() {
      const res = Math.ceil(this.total / this.perPage);

      return res;
    },
    array() {

        if (this.lastPage <= 0) {
            return [];
        }

        let from = this.page - this.config.offset;
        if (from < 1) {
            from = 1;
        }

        let to = from + (this.config.offset * 2);
        if (to >= this.lastPage) {
            to = this.lastPage;
        }

        let arr = [];
        while (from <= to && arr.length < 5) {
            arr.push(from);
            from++;
        }

        return arr;
    },
    config() {
      return Object.assign({
          offset: 3,
          ariaPrevious: 'Previous',
          ariaNext: 'Next',
          previousText: '«',
          nextText: '»',
          alwaysShowPrevNext: false
      }, this.options);
    },
  },
  watch: {
  
  },
  methods:{
    showPrevious() {
      return this.config.alwaysShowPrevNext || this.page > 1;
    },
    showNext() {
        return this.config.alwaysShowPrevNext || this.page < this.lastPage;
    },
    changePage(page) {
      if (this.page === page) {
        return;
      }

      this.$emit('change', page);
    }
  }
};
</script>

<template>
  <div>
    <ul class="pagination" :class="{'pagination-sm': small}">
      <li v-if="showPrevious()" :class="{ 'disabled' : page <= 1 }" class="page-item">
          <a v-if="page <= 1" class="page-link">
              <i class='fa fa-angle-double-left'/>
          </a>
          <a href='javascript:void(0)' v-if="page > 1 " :aria-label="config.ariaPrevioius" @click.prevent="changePage(page - 1)" class="page-link">
              <i class='fa fa-angle-left'/>
          </a>
      </li>

      <li v-for="num in array" :key="num" :class="{ 'active': num === page }"  class="page-item">
          <a href="javascript:void(0)" class="page-link" @click.prevent="changePage(num)">{{ num }}</a>
      </li>

      <li v-if="showNext()" :class="{ 'disabled' : page === lastPage || lastPage === 0 }"  class="page-item">
          <a  class="page-link" v-if="page === lastPage || lastPage === 0">
              <i class='fa fa-angle-double-right'/>
          </a>
          <a class="page-link" href='javascript:void(0)' v-if="page < lastPage"  @click.prevent="changePage(page + 1)">
              <i class='fa fa-angle-right'/>
          </a>
      </li>
    </ul>
  </div>
</template>
