<script>
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";
import CustomField from '@/components/custom-fields/custom-field';
import { mapGetters } from 'vuex'

export default {
  components: {
    CustomField
  },
  props: {
    def:{
       type: Object,
       required: true
    },
    value:{
       type: Object,
       required: true
    }
  },
  data() {
    return {
      p_value: null,
      tryToSave: false,
      errors: []
    };
  },
  created(){
    this.title = this.def.name;
    this.populate(this.value);
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    ...mapGetters('directories', {
      directories: 'items',
      allCustomFields: 'allCustomFields'
    })
  },
  methods: {
    async close(res) {
       var modal = Modal.getInstance(this.$refs.modal);
       modal.hide();
      popModal();
       this.$emit("close", res);
    },
    async populate(source) {
      this.p_value = JSON.parse(JSON.stringify(source));
    },
    async save () {
      
      this.tryToSave = true;
      this.errors = [];
      this.def.tableFields.forEach(f => {
        if(f.required) {
          if(!this.p_value[f.id]) {
            this.errors = [...this.errors, this.$t('common.field') + ' ' + f.name + ' ' + this.$t('common.isRequired')]
          }
        }
      });

      if(this.errors.length === 0) {
      
        this.$emit('save', this.p_value);
        this.close();
      }
    },
    getRelatedFieldDef(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const directoryRelatedField = (this.allCustomFields[directoryRelatedFieldId] || null);
          return directoryRelatedField;
        }
      }
      return null;
    },
    getRelatedFielValue(directoryFieldId, directoryRelatedFieldId) {
      const refField = this.def.tableFields.find(x => x.id === directoryFieldId);
      if(refField) {
        const directoryId = refField.directoryId;
        const directory = this.directories.find(x => x.id === directoryId);
        if(directory) {
          const dirValue = this.p_value[directoryFieldId];
          if(dirValue) {
            return dirValue.customFields[directoryRelatedFieldId];
          }
        }
      }
      return null;
    }
  }
};
</script>

<template>
  <div
    class="modal"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
            
            {{title}}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

          </div>

          <template v-for="tableField in (def.tableFields || []).filter(x => !x.hidden)" :key="tableField.id">
            <div class="mb-4" v-if="tableField.type !== 'DirectoryRelatedField' || p_value[tableField.directoryFieldId]">
              <label class="mb-0 ">{{tableField.name}}</label>
              <div v-if="tableField.type !== 'DirectoryRelatedField'">
                <custom-field-edit :def="tableField" :value="p_value[tableField.id]" @update="(v) => p_value[tableField.id] = v">
                </custom-field-edit>
              </div>
              <div v-if="tableField.type === 'DirectoryRelatedField' && getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId)">
             
                <custom-field :def="getRelatedFieldDef(tableField.directoryFieldId, tableField.directoryRelatedFieldId)" :value="getRelatedFielValue(tableField.directoryFieldId, tableField.directoryRelatedFieldId)">

                </custom-field>
              </div>
            </div>
          </template>
          
        </div>
        <div class="modal-footer">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-light me-2"
              @click="close"
            >
              {{$t('common.closeButtonTitle')}}
            </button>
          </div>
          <div class="col text-end">
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="save"
            >
              <i class="fa fa-save me-2"></i>
              {{$t('common.saveButtonTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
