<script>

export default {
  props: {
    reminder: {
      type: Object
    }
  }
};
</script>

<template>
<span>
  <span v-if="!reminder.cancelledAt">
    <span v-if="reminder.handled" class="badge rounded-pill bg-success">
      handled
    </span>
    <span v-if="reminder.active" class="badge rounded-pill bg-primary">
      active
    </span>
    <span v-if="!reminder.active && !reminder.handled" class="badge rounded-pill badge-soft-dark">
      upcoming
    </span>
  </span>
  <span v-if="reminder.cancelledAt">
    <span class="badge rounded-pill bg-warning">
      cancelled
    </span>
  </span>
 </span>
</template>
