<script>
import { mapGetters } from 'vuex';

export default {
  components: {
   
  },
  props:{
    
  },
  data() {
    return {
     
    };
  },
 
  computed :{
    ...mapGetters('boot', {
      ready: 'ready'
    })
  },
  methods: {
  }
};
</script>


<style scoped lang="scss">
.boot {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9997;
  height: 100%;
  padding: 20px;
  

  .card-container {
    position: absolute;
    left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
    border: 1px solid #eff0f2;
    border-radius: 1rem;
  
  
  }
}

.overlay {
  background-color: rgba(20, 27, 43, 0.55);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}
</style>

<template>
  <div>
    <transition name="fade">
      <div class="boot bg-light" v-if="!ready">
        <div class="overlay">

        </div>
        <div class="card-container bg-white d-flex align-items-center">
          <div class="box w-100 text-center fs-1">
            <div class="mb-4">
              <img src="@/assets/images/logo-dark.png" alt="" height="36" />
            </div>
            <i class="fa fa-spin fa-spinner "></i> 
          </div>
        </div>
      </div>
    </transition>
  </div>
  
</template>
