<template>
 
    <label class=" p-0 text-muted" style="cursor: pointer;">
      <input
      
        type="file"
        ref="file"
        v-on:change="emitFileChange"
        v-show="false"
        :accept="acceptType"
      />
      
      <span class="btn btn-light btn-sm">
        <eva-icon class="icon nav-icon me-1" name="plus-outline"></eva-icon>
        {{$t('common.uploadFileBtnTitle')}}
      </span>
    </label>
 
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import Swal from 'sweetalert2'


export default defineComponent({
  name: 'FileInput',
  props: {
   
    buttonBackgroundColor: {
      type: String,
      default: () => '#003e70'
    },
    buttonTextColor: {
      type: String,
      default: () => '#FFF'
    },
    placeholderInputText: {
      type: String,
      default: () => 'Select a file'
    },
    placeholderButtonText: {
      type: String,
      default: () => 'Select a file'
    }
  },
  setup (props, context) {
    const file = ref(null)
    const FileName = ref(null)

    const acceptType = computed(() => {
      if (props.isExcel) {
        return '.xlsx, .xls'
      } else if (props.isWord) {
        return '.doc, .docx'
      } else if (props.isImage) {
        return 'image/*'
      } else if (props.isVideo) {
        return 'video/*'
      } else if (props.isPdf) {
        return 'application/pdf'
      } else if (props.isAudio) {
        return 'audio/*'
      } else {
        return '*'
      }
    })

    const buttonStyle = computed(() => {
      return `background-color: ${props.buttonBackgroundColor}; color: ${props.buttonTextColor};`
    })

    const emitFileChange = () => {
      if (file.value.files.length > 0) {
        const fileAux = file.value.files[0];

        const fileReader = new FileReader();
        fileReader.onload = () => {
          let MB = 1024*1024*30;
         // console.log(fileReader.result);
          const blob = new Blob([fileReader.result], {
            // This will set the mimetype of the file
            type: fileAux.type
          });
        
          //console.log(blob.size);
          if (blob.size > MB) {
            Swal.fire(
              'Unable send file',
              'File size is to big!',
              'warning'
            );

            return new Error('File size is to big');
          }
          const response = {
            file: fileAux,
            fileName: fileAux.name,
            fileBlob: blob,
            fileType: fileAux.type
          }

          context.emit('update:modelValue', response)
          context.emit('ready');
        };

        fileReader.readAsArrayBuffer(fileAux)
        
      } else {
        context.emit('update:modelValue', null)
      }
    }

    onMounted(() => {
     
    })

    return {
      FileName,
      acceptType,
      buttonStyle,
      emitFileChange,
      file
    }
  }
})
</script>

<style scoped>

</style>