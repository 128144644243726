<script>
import { Modal } from 'bootstrap';
import { popModal, pushModal } from "@/modals";

export default {
  components: {
  },
  props: {
    url: {
      type: String,
    },
    fileName: {
      type: String,
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
   
  },
  mounted(){

    console.log('mounted file preview form');
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });

   
    this.$refs.iframe.onload = () => {
      console.log('iframe loaded');
     //  this.style.height =
      //this.contentWindow.document.body.offsetHeight + 'px';
    };

    //let intFrameHeight = window.innerHeight;
    //this.$refs.iframe.style.height = (intFrameHeight - 100) + 'px';
  //   //let intFrameWidth = window.innerWidth;
  //   
  //  // intFrameWidth = intFrameWidth * 0.9;
  //   intFrameHeight = intFrameHeight * 0.9;
  //   // // this.$refs.iframe.style.width = intFrameWidth + 'px';
  //   this.$refs.iframe.style.height = intFrameHeight + 'px';
    // this.$refs.modalDialog.style.maxWidth = intFrameWidth + 'px';
    // this.$refs.content.style.height = intFrameHeight + 'px';
  },
  methods: {
     async close() {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close");
    },
  }
};
</script>

<template>
  <div>
    <div
      class="modal"
      ref="modal"
    >
      <div class="modal-dialog modal-fullscreen" role="document" ref="modalDialog">
        <div class="modal-content" ref="content">
          <div class="modal-header">
            <a :href="url" target="_blank" class="text-nowrap text-truncate btn btn-primary">
               <font-awesome-icon icon="fa-solid fa-download"  class="me-1"/> {{$t('common.download')}}: {{fileName}}
            </a>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="close"
            ></button>
          </div>
          <div class="modal-body" style="padding: 0">
            
            <iframe :src="url" ref="iframe" style="width:100%; height: 99%;"  allowTransparency="true" frameborder="0" scrolling="yes">
            </iframe> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
