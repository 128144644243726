<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    }
  }, 
  computed: {
    circleStyle() {
      return {
        'background-color': this.color,
        'border-color': this.color,
      };
    }
  }
};
</script>

<template>
  <span class="state-name">
    <span class="state-name-circle me-1" :style="circleStyle"></span>
  </span>
</template>
